import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts/Layout';
import Image from 'gatsby-image';
import styled from 'styled-components';
import BigTitle from '../components/shared/text/BigTitle';
import Section from '../components/shared/wraps/Section/Section';
import Content from '../components/shared/Content';
import ReactHtmlParser from 'react-html-parser';
import SEO from '../components/seo';

const ImageWrapper = styled.div`
  margin: 3rem auto;
  max-width: 120rem;
`;

const StyledBigTitle = styled(BigTitle)`
  margin-bottom: 3.5rem;
`;

export const query = graphql`
  query projectsQuery($id: String!) {
    directusProjekty(id: { eq: $id }) {
      title
      slug
      gallery {
        title
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      description
    }
  }
`;

const Project = ({ data, location: { pathname: path } }) => {
  const sortedImages = data.directusProjekty.gallery.sort((a, b) =>
    a.title.toUpperCase() > b.title.toUpperCase() ? 1 : -1,
  );

  const { slug, title, description } = data.directusProjekty;
  return (
    <Layout path={path} id={slug}>
      <SEO title={`Projekt - ${title}`} />
      <Section nonGrid site nonFlex first>
        <StyledBigTitle small>{title}</StyledBigTitle>
        <Content>{ReactHtmlParser(description)}</Content>
        {sortedImages.map((image, index) => (
          <ImageWrapper key={index}>
            <Image fluid={image.localFile.childImageSharp.fluid} />
          </ImageWrapper>
        ))}
      </Section>
    </Layout>
  );
};

export default Project;
